import { lighten, darken } from "polished";

const primaryColor: string = "#FFB784";
const secondaryColor: string = "#23FDF5";
const tertiaryColor: string = "#65181E";
const accentColor: string = "#F73848";

const appTitle: string = "Alqsy - Your Go-To NFT Guru";
const metaDescription: string =
  "Alqsy is the ultimate NFT rockstar, making waves as one of the top 100 youngest and most influential names in the NFT industry";
const metaKeywords: string = "nft, bayc, marketing";

const googleFonts: string =
  "https://fonts.googleapis.com/css2?family=Kameron:wght@400;700&family=Rubik&display=swap";

const fontFamilies: string = "'Kameron', serif,'Rubik', sans-serif";
const headlineFont: string = "'Kameron', serif";
const bodyFont: string = "'Rubik', sans-serif";

const headlineFontStyles: React.CSSProperties = {
  fontFamily: headlineFont,
  fontWeight: "normal",
  fontStyle: "normal",
  // letterSpacing: "-0.02em",
};

const commonColorStyles: { [key: string]: string } = {
  white: "#fff",
  black: "#000",
  grey: "#696a6c",
  lightGrey: "#fafafa",
};

const customColorStyles: {
  tertiary: { light: string; main: string; dark: string };
  accent: { light: string; main: string; dark: string };
} = {
  tertiary: {
    light: lighten(0.1, tertiaryColor),
    main: tertiaryColor,
    dark: darken(0.1, tertiaryColor),
  },
  accent: {
    light: lighten(0.1, tertiaryColor),
    main: accentColor,
    dark: darken(0.1, tertiaryColor),
  },
};

export {
  googleFonts,
  primaryColor,
  secondaryColor,
  accentColor,
  fontFamilies,
  headlineFont,
  headlineFontStyles,
  commonColorStyles,
  bodyFont,
  metaDescription,
  metaKeywords,
  appTitle,
  customColorStyles,
};
